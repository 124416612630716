import axiosPost from "./axiosPost";

export const docenteCalendarioIns = async (credentials, data) => {
    const body = {
        service: "NQNDocenteCalendarioIns",
        params: {
            nqnDocenteId: data?.userId,
            nqnDocenteCalendarioHoras: Number(data?.horas),
            nqnDocenteCalendarioHorasLu: Number(data?.horasLu),
            nqnDocenteCalendarioHorasMa: Number(data?.horasMa),
            nqnDocenteCalendarioHorasMi: Number(data?.horasMi),
            nqnDocenteCalendarioHorasJu: Number(data?.horasJu),
            nqnDocenteCalendarioHorasVi: Number(data?.horasVi),
            nqnDocenteCalendarioFecha: data?.date,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
