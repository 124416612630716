import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { distritosGet } from "services/services-mia/distritosGet";
import { escuelasIns } from "services/services-mia/escuelasIns";
import { Box, Grid } from "@mui/material";
import { localidadesGet } from "services/services-mia/localidadesGet";
import { nivelModalidadGet } from "services/services-mia/nivelModalidadGet";
import { calendarioGet } from "services/services-mia/calendarioGet";

const libraries = ["places"];

const containerStyle = {
    width: "100%",
    maxWidth: "726px",
    // height: "100%",
    marginBottom: ".2rem",
    marginTop: "2rem",
    display: "flex",
    justifyContent: "end",
};
export default function InsertModal({ updateList, closeModal, latitud, longitud }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [localidades, setLocalidades] = useState([]);
    const [calendario, setCalendario] = useState([])
    const [distritos, setDistritos] = useState([]);
    const [nivelModalidad, setNivelModalidad] = useState([]);
    const [coordenadas, setCoordenadas] = useState({
        lat: latitud
            ? typeof latitud === "number"
                ? latitud
                : parseFloat(latitud)
            : -38.951666,
        lng: longitud
            ? typeof longitud === "number"
                ? longitud
                : parseFloat(longitud)
            : -68.074444,
    });

    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const getData = async () => {
        setIsLoading(true);
        const dist = await distritosGet(credentials)
        const loc = await localidadesGet(credentials)
        const nivMod = await nivelModalidadGet(credentials, { activo: "Y" })
        const cal = await calendarioGet(credentials)
        setNivelModalidad(nivMod);
        setDistritos(dist);
        setCalendario(cal)
        setLocalidades(loc);
        setIsLoading(false);
    }
    const handleCreateEscuela = async (values) => {
        const calCiclo = calendario.find((c) => c.nqnCalendarioId === Number(values.calendario))
        if (calCiclo.ciclosCount === 0) {
            setSnackBarInfo({
                message: "El calendario seleccionado no tiene ciclos",
                severity: "warning",
                open: true,
            });
            return
        } else {
            const response = await escuelasIns(
                credentials,
                { ...values, lat: coordenadas.lat, lng: coordenadas.lng }
            );
            if (response.length === 0) {
                setSnackBarInfo({
                    message: "Escuela creada correctamente",
                    severity: "success",
                    open: true,
                });
                formikRef.current.resetForm();
                updateList();
                closeModal();
            }
        }
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                escuelaId: "",
                cue: "",
                name: "",
                calle: "",
                casa: "",
                localidadId: "",
                cpa: "",
                version: "",
                calendario: "",
                distritoId: "",
                nivelModalidadId: "",
                tolerancia: 0
            }}
            validationSchema={
                yup.object({
                    cue: yup.string("Debe ingresar un CUE").max(20, "Debe tener menos de 20 caracteres").required("Debe ingresar un CUE"),
                    name: yup.string("Debe ingresar un nombre").max(100, "Debe tener menos de 100 caracteres").required("Debe ingresar un nombre"),
                    calle: yup.string("Debe ingresar una calle").max(70, "Debe tener menos de 70 caracteres").required("Debe ingresar una calle"),
                    casa: yup.string("Debe ingresar un numero").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un numero"),
                    localidadId: yup.string("Debe seleccionar una localidad").required("Debe seleccionar una localidad"),
                    cpa: yup.string().max(8, "Debe tener 8 caracteres").min(8, "Debe tener 8 caracteres").required("Debes ingresar tu codigo postal"),
                    calendario: yup.string("Debe seleccionar un calendario").required("Debe seleccionar un calendario"),
                    distritoId: yup.string("Debe seleccionar un distrito").required("Debe seleccionar un distrito"),
                    nivelModalidadId: yup.string("Debe seleccionar un nivel").required("Debe seleccionar un nivel"),
                    tolerancia: yup.number("Debe ingresar una tolerancia").typeError("Debe ingresar una tolerancia").required("Debe ingresar una tolerancia"),
                })
            }
            onSubmit={(values) => {
                handleCreateEscuela(values);
            }}
        >
            <Form className={styles.inputsBig} noValidate>
                <div className={styles["column-sm"]}>
                    <Grid width={"100%"}>
                        {step.basico && !isLoading && <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={4}>
                            <Box display={"flex"} width={"100%"} gap={4}>
                                <FormikTextInput
                                    fullWidth={true}
                                    name="cue"
                                    labelText="CUE"
                                />
                                <FormikTextInput
                                    fullWidth={true}
                                    name="name"
                                    labelText="Nombre"
                                />
                            </Box>
                            <Box display={"flex"} width={"100%"} gap={4}>
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="distritoId"
                                    labelText="Distrito"
                                    onChange={(e) => {
                                        formikRef.current.setFieldValue("distritoId", e.target.value);
                                    }}
                                >
                                    <option value={""} selected disabled >
                                        Seleccione
                                    </option>
                                    {distritos.map(({ nqnDistritoId, nqnDistritoNombre, nqnDistritoVersion }) => (
                                        <option
                                            key={nqnDistritoId}
                                            data-id={nqnDistritoVersion}
                                            value={nqnDistritoId}
                                        >
                                            {nqnDistritoNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="nivelModalidadId"
                                    labelText="Nivel"
                                    onChange={(e) => {
                                        formikRef.current.setFieldValue("nivelModalidadId", e.target.value);
                                    }}
                                >
                                    <option value={""} selected disabled >
                                        Seleccione
                                    </option>
                                    {nivelModalidad.map(({ nqnNivelModalidadId, nqnNivelModalidadNombre }) => (
                                        <option
                                            key={nqnNivelModalidadId}
                                            data-id={nqnNivelModalidadId}
                                            value={nqnNivelModalidadId}
                                        >
                                            {nqnNivelModalidadNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="calendario"
                                    labelText="Tipo de calendario"
                                    onChange={(e) => {
                                        formikRef.current.setFieldValue("calendario", e.target.value);
                                    }}
                                >
                                    <option value={""} selected disabled >
                                        Seleccione
                                    </option>
                                    {calendario.map(({ nqnCalendarioId, nqnCalendarioNombre }) => (
                                        <option
                                            key={nqnCalendarioId}
                                            data-id={nqnCalendarioId}
                                            value={nqnCalendarioId}
                                        >
                                            {nqnCalendarioNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                            </Box>
                        </Box>}
                        <Box display={"flex"} width={"100%"} gap={4}>
                            {step.basico && isLoading ? <div style={{ minWidth: "30%" }}><CircularIndeterminate /></div> : <Box display={"flex"} flexDirection={"column"} minWidth={"30%"} gap={2}>
                                <h3 style={{ margin: "0", marginTop: "32px" }}>Domicilio</h3>
                                <FormikTextInput
                                    fullWidth={true}
                                    name="calle"
                                    labelText="Calle"
                                />
                                <FormikTextInput
                                    fullWidth={true}
                                    name="casa"
                                    labelText="Numero"
                                />
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="localidadId"
                                    labelText="Localidad"
                                    onChange={(e) => {
                                        formikRef.current.setFieldValue("localidadId", e.target.value);
                                        let locCoords = localidades.filter((i) => i.localidadId === Number(e.target.value));
                                        console.log(locCoords);
                                        setCoordenadas({ lat: locCoords[0].localidadGeoPINLatitud, lng: locCoords[0].localidadGeoPINLongitud });
                                    }}
                                >
                                    <option value={""} selected disabled >
                                        Seleccione
                                    </option>
                                    {localidades.map(({ localidadId, localidadNombre }) => (
                                        <option
                                            key={localidadId}
                                            data-id={localidadId}
                                            value={localidadId}
                                        >
                                            {localidadNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                                <FormikTextInput
                                    fullWidth={true}
                                    name="cpa"
                                    labelText="CPA"
                                />
                                <FormikTextInput
                                    fullWidth={true}
                                    name="tolerancia"
                                    labelText="Tolerancia"
                                />
                            </Box>}
                            <LoadScript
                                id="second-google-map-script"
                                googleMapsApiKey="AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc"
                                libraries={libraries}
                                style={{ width: "50%", height: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}
                            >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={coordenadas}
                                    zoom={8}
                                >
                                    <Marker position={coordenadas} draggable onDragEnd={(e) =>
                                        setCoordenadas({
                                            lat: e.latLng.lat(),
                                            lng: e.latLng.lng(),
                                        })
                                    } />
                                </GoogleMap>
                            </LoadScript>
                        </Box>
                    </Grid>

                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>
        </Formik>
    );
}
