import { render } from "@testing-library/react";

export const usuariosXServicio = [
    {
        field: "usuarioNombreCompleto",
        headerName: "Apellido y Nombre",
        flex: 1,
        // minWidth: 220,
    },
    {
        field: "usuarioNroDeDocumento",
        headerName: "Documento",
        type: "number",
        format: "number",
        flex: 1,
        maxWidth: 140,
    },
    {
        field: "usuarioSexo",
        headerName: "Género",
        flex: 1,
        maxWidth: 140,
    },
    {
        field: "usuarioMail",
        headerName: "Email",
        flex: 1,
    },
    {
        field: "telefono",
        headerName: "Teléfono",
        minWidth: 140,
    },
    {
        field: "rolNombre",
        headerName: "Rol",
        flex: 1,
    },
    {
        field: "nivelNombre",
        headerName: "Nivel",
        flex: 1,
    },
    {
        field: "onboard",
        headerName: "Onboard",
        align: "left",
        maxWidth: 100,
    },
    {
        field: "usuarioFechaDeAlta",
        headerName: "Alta",
        type: "date",
        format: "date",
        maxWidth: 140,
    },
    {
        field: "relacionStatus",
        headerName: "Estado",
        maxWidth: 140,
    },
    {
        field: "usuarioFechaDeActivo",
        headerName: "Fecha",
        type: "date",
        maxWidth: 140,
        format: "date",
    },
];
export const feriadosGrid = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "typeName",
        headerName: "Tipo",
        flex: 1,
        minWidth: 180,
    },
];
export const calendarioGrid = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cod",
        headerName: "Codigo",
        flex: 1,
        minWidth: 180,
    },
];
export const nivelesGrid = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cod",
        headerName: "Código",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "activo",
        headerName: "Activo",
        flex: 1,
        minWidth: 180,
    },
];
export const feriadosSerieGrid = [
    {
        field: "date",
        headerName: "Fecha",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "typeName",
        headerName: "Tipo",
        flex: 1,
        minWidth: 180,
    },
];
export const ciclosLectivosGrid = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "año",
        headerName: "Año",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "tramo",
        headerName: "Tramo",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "fechaDesde",
        headerName: "Desde",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "fechaHasta",
        headerName: "Hasta",
        flex: 1,
        minWidth: 180,
    },
];
export const distritosGrid = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cod",
        headerName: "Código",
        flex: 1,
        minWidth: 180,
    },
];
export const cargosGrid = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cod",
        headerName: "Código",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "tipoNombre",
        headerName: "Tipo",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "nivelModalidad",
        headerName: "Nivel",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "horas",
        headerName: "Horas",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "esDirectivo",
        headerName: "Directivo",
        flex: 1,
        // minWidth: 180,
    },
    {
        field: "esVisible",
        headerName: "Visible",
        flex: 1,
        // minWidth: 180,
    },
];
export const escuelasHistorialGrid = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "motivo",
        headerName: "Motivo",
        flex: 1,
        minWidth: 180,
    },
];
export const escuelasGrid = [
    {
        field: "cue",
        headerName: "CUE",
        flex: 1,
        minWidth: 80,
    },
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "distrito",
        headerName: "Distrito",
        flex: 1,
        minWidth: 140,
    },
    {
        field: "nivelModalidad",
        headerName: "Nivel",
        flex: 1,
        minWidth: 140,
    },
    {
        field: "estado",
        headerName: "Estado",
        flex: 1,
        minWidth: 100,
    },
    {
        field: "date",
        headerName: "Desde",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "motivo",
        headerName: "Motivo",
        flex: 1,
        minWidth: 150,
    },
    {
        field: "calendario",
        headerName: "Calendario",
        flex: 1,
        minWidth: 160,
    },
    {
        field: "calle",
        headerName: "Calle",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "casa",
        headerName: "Casa",
        flex: 1,
        minWidth: 80,
    },
    {
        field: "localidad",
        headerName: "Localidad",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "lat",
        headerName: "Latitud",
        flex: 1,
        minWidth: 80,
    },
    {
        field: "lng",
        headerName: "Longitud",
        flex: 1,
        minWidth: 80,
    },
    {
        field: "tolerancia",
        headerName: "Tolerancia",
        flex: 1,
        minWidth: 90,
        headerAlign: "right",
        align: "right"
    },
    {
        field: "cpa",
        headerName: "CPA",
        // flex: 1,
        minWidth: 80,
    },
];
export const docentesGrid = [
    {
        field: "name",
        headerName: "Nombres",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "lastname",
        headerName: "Apellidos",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "dni",
        headerName: "DNI",
        flex: 1,
        minWidth: 100,
    },
    {
        field: "genero",
        headerName: "Genero",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "escuelaNombre",
        headerName: "Escuela",
        flex: 1,
        minWidth: 100,
    },
    {
        field: "cargoNombre",
        headerName: "Cargo",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "tipoCargo",
        headerName: "Tipo de cargo",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "horas",
        headerName: "Horas",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "status",
        headerName: "Estado",
        flex: 1,
        minWidth: 80,
    },
    {
        field: "fechaStatus",
        headerName: "Fecha",
        flex: 1,
        minWidth: 120,
    },
    // {
    //     // field: "calendario",
    //     headerName: "Calendario",
    //     flex: 1,
    //     minWidth: 50,
    //     renderCell: (params) => {
    //         <div style={{backgroundColor: "rgba(2, 137, 59, 0.10) !important", width: "56px !important", padding: "8px", borderRadius:"60px", height:"8px !important", display: "flex", alignItems: "center", gap: "4px"}}>
    //     <div style={{backgroundColor: "#02893B !important", width:"8px !important", height:"8px !important", borderRadius: "50%"}}></div>
    //     <p
    //       style={{color: "#02893B !important", margin:0, fontSize: "14px"}}
    //     >
    //       Hola
    //     </p>
    //   </div>
    //     }
    // },
];
export const movimientosBeneficiario = [
    "Fecha",
    "Cantidad",
    "Artículo",
    "Entregado por",
    "Identificador",
    "Latitud y Longitud",
];

export const movimientosDespachante = [
    "Fecha",
    "Cantidad",
    "Artículo",
    "Entregado a",
    "Identificador",
    "Latitud y Longitud",
];

export const resumenDisponibles = ["Cantidad", "Disponible", "Origen", "Fecha"];
export const resumenTransferencias = [
    "Cantidad",
    "Disponible",
    "Transferido a ",
    "Fecha",
];
export const resumenRetiro = [
    "Cantidad",
    "Disponible",
    "Despachante ",
    "Fecha",
];
