import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikDateInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { Box, Grid } from "@mui/material";
import { docentesGet } from "services/services-mia/docentesGet";
import { docenteCalendarioIns } from "services/services-mia/docentesCalendarioIns";
import { InfoCard } from "components/InfoCard/InfoCard";

const sexo = [
    { value: "A", name: "Alta", id: "1" },
    { value: "B", name: "Baja", id: "2" },
];

export default function UpdateModal({ updateList, roles, user, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [hr, setHr] = useState(false);

    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getData = async () => {
        setIsLoading(true);
        const result = await docentesGet(credentials, { id: user.id })
        console.log(result)
        setHr(result?.result[0]?.nqnCargoTipo)
        formikRef.current.setValues({
            userId: result.result[0].nqnDocenteId,
            horas: result.result[0].nqnDocenteHoras,
            horasLu: result.result[0].nqnDocenteHorasLu,
            horasMa: result.result[0].nqnDocenteHorasMa,
            horasMi: result.result[0].nqnDocenteHorasMi,
            horasJu: result.result[0].nqnDocenteHorasJu,
            horasVi: result.result[0].nqnDocenteHorasVi,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        const response = await docenteCalendarioIns(credentials, values)
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Docente modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getData()
    }, []);
    const dataFormik = {
        date: { label: "Fecha" },
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                horas: 0,
                horasLu: "",
                horasMa: "",
                horasMi: "",
                horasJu: "",
                horasVi: "",
                tipoHr: "",
                date: "",
                userId: ""
            }}
            validationSchema={
                yup.object({
                    date: yup.date().required('La fecha es obligatoria'),
                    horas: yup.number().required("Las horas son obligatorias").min(1, "Las horas tienen que ser mayores a 0"),
                })
            }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <InfoCard nombre={user.name} apellido={user.lastname} escuela={user.escuelaNombre} cargo={user.cargoNombre} />
                <div className={styles["column-sm"]}>
                    {step.basico && <Grid width={"100%"}>
                        <Box display={"flex"} width={"100%"} gap={4}>

                            <FormikDateInput
                                data={dataFormik}
                                formik={formikRef.current}
                                fullWidth={true}
                                name="date"
                                labelText="Fecha"
                                handleChange={(e) => {
                                    formikRef.current.setFieldValue("date", e.target.value);
                                }}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horas"
                                labelText="Horas"
                                disabled={hr !== "C"}
                            />
                        </Box>
                        <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikTextInput
                                fullWidth={true}
                                name="horasLu"
                                labelText="Lunes"
                                disabled={hr === "D"}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horasMa"
                                labelText="Martes"
                                disabled={hr === "D"}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horasMi"
                                labelText="Miercoles"
                                disabled={hr === "D"}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horasJu"
                                labelText="Jueves"
                                disabled={hr === "D"}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horasVi"
                                labelText="Viernes"
                                disabled={hr === "D"}
                            />
                        </Box>
                    </Grid>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Aplicar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
