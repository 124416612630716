import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { userCredentials, userServices } from 'recoilState/GlobalState';

import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, StatusModal, DeleteModal } from "./Actions";
import { Button, Buttons, HeaderButton } from "components";
import { ACTIONS } from "consts/Actions";
import { Add, Cached, DoNotDisturbAltRounded, CalendarMonthRounded, EditCalendarRounded, EventRepeatRounded, DeleteOutlineRounded } from '@mui/icons-material';
import moment from 'moment';
import { distritosGet } from 'services/services-mia/distritosGet';
import { docentesGet } from 'services/services-mia/docentesGet';
import styles from "./Docentes.module.scss"
import Filters from 'components/Filters';
import { useFormik } from 'formik';
import { escuelasGet } from 'services/services-mia/escuelasGet';
import { cargosGet } from 'services/services-mia/cargosGet';
import XGridServer from 'components/UI/Grid/XGridServer';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';

const estadosPP = [
    {
        id: "",
        nombre: "Todos",
    },
    {
        id: "S",
        nombre: "Ok",
    },
    {
        id: "W",
        nombre: "Controlar",
    },
    {
        id: "D",
        nombre: "Corregir",
    },
]

export default function Docentes() {
    const titles = [
        {
            field: "lastname",
            headerName: "Apellidos",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "name",
            headerName: "Nombres",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "docFormat",
            headerName: "DNI",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "escuelaNombre",
            headerName: "Escuela",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "cargoNombre",
            headerName: "Cargo",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "tipoCargo",
            headerName: "Tipo de cargo",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "horas",
            headerName: "Horas Esc",
            // headerAlign: "right",
            flex: 1,
            maxWidth: 100,
            align: "right",
            renderCell: (params) => cellHoras(params)
        },
        {
            field: "horasPP",
            headerName: "Horas PP",
            // headerAlign: "right",
            flex: 1,
            maxWidth: 90,
            align: "right",
            renderCell: (params) => cellHorasPP(params)
        },
        {
            field: "status",
            headerName: "Estado",
            flex: 1,
            maxWidth: 100,
        },
        {
            field: "fechaStatus",
            headerName: "Fecha",
            // flex: 1,
            minWidth: 100,
        },
        {
            field: "esDirectivo",
            headerName: "Directivo",
            // flex: 1,
            minWidth: 50,
        },
        {
            field: "calendario",
            headerName: "",
            // flex: 1,
            minWidth: 50,
            renderCell: (params) => cellCalendar(params)
        },
    ];

    const credentials = useRecoilValue(userCredentials);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [forceUpload, setForceUpload] = useState(false);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} });
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [escuelas, setEscuelas] = useState([]);
    const [docentes, setDocentes] = useState([]);
    const [distritos, setDistritos] = useState([])
    const [cargos, setCargos] = useState([]);
    const permisos = useRecoilValue(userServices);
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            escuelaId: "",
            doc: "",
            estadoId: "A",
            cargoId: "",
            estadoPP: ""
        },
        onSubmit: (values) => {
            updateList()
        },
    });

    const getDocentesData = async (page, pageSize) => {
        // setDocentes([]);
        setIsLoading(true)
        const data = {
            rolCod: null,
            page: page + 1,
            pageSize,
            usuarioTieneApp: null,
            usuarioNroDeDocumentoX: formik.values.usuarioNumeroDeDocumentoX || null,
            usuarioApellidos: formik.values.usuarioApellidos || null,
            merchantServicioUsuarioStatus: formik.values.merchantServicioUsuarioStatus || null,
            escuelaId: formik.values.escuelaId,
            doc: formik.values.doc.replaceAll(".", ""),
            estadoId: formik.values.estadoId,
            cargoId: formik.values.cargoId,
            estadoPP: formik.values.estadoPP,
        }
        console.log("Ingresoo a getDocentesData")
        const { result, total } = await docentesGet(credentials, data);
        // const total = 20
        // const newRows = 20
        const newRows = result ? result.map((item) => {
            return {
                id: item.nqnDocenteId,
                name: item.usuarioNombres,
                lastname: item.usuarioApellidos,
                dni: item.nqnDocenteNroDeDocumento,
                docFormat: new Intl.NumberFormat("de-DE").format(item.nqnDocenteNroDeDocumento),
                genero: item.nqnDocenteSexo === "M" ? "Masculino" : item.nqnDocenteSexo === "F" ? "Femenino" : "No binario",
                escuelaNombre: item.nqnEscuelaNombre,
                cargoNombre: item.nqnCargoNombre,
                tipoCargo: item.nqnCargoTipo === "C" ? "Horas cátedra" : item.nqnCargoTipo === "S" ? "Horas semanales" : "Horas diarias",
                status: item.nqnDocenteStatus === "A" ? "Alta" : "Baja",
                fechaStatus: item.nqnDocenteStatusFecha ? moment(item.nqnDocenteStatusFecha).format("DD/MM/YYYY").toString() : null,
                horas: {
                    status: item.nqnDocenteStatus,
                    horasEsc: item.nqnDocenteHoras || 0,
                    horasPP: item.nqnDocenteCalendarioHorasPlanta || 0,
                    informado: item.nqnDocenteCalendarioInformadoPP,
                    cargoTipo: item.nqnCargoTipo
                },
                horasPP: item.nqnDocenteCalendarioHorasPlanta,
                calendario: [{ day: "Lunes", hrs: item.nqnDocenteHorasLu }, { day: "Martes", hrs: item.nqnDocenteHorasMa }, { day: "Miercoles", hrs: item.nqnDocenteHorasMi }, { day: "Jueves", hrs: item.nqnDocenteHorasJu }, { day: "Viernes", hrs: item.nqnDocenteHorasVi }],
                horasLu: item.nqnDocenteHorasLu,
                horasMa: item.nqnDocenteHorasMa,
                horasMi: item.nqnDocenteHorasMi,
                horasJu: item.nqnDocenteHorasJu,
                horasVi: item.nqnDocenteHorasVi,
                esDirectivo: item.nqnCargoEsDirectivo === "Y" ? "Si" : "No",
                informadoPP: item.nqnDocenteCalendarioInformadoPP
            };
        }) : [];
        setIsLoading(false);
        return {
            rows: newRows,
            rowCount: total,
        };
    }
    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const cellHoras = (params) => {
        let color = params.value.status === "B" || (params.value.status === "A" && params.value.informado === "Y" && (params.value.cargoTipo === "C" && params.value.horasEsc === params.value.horasPP)) ? "green" : params.value.status === "A" && params.value.informado === "Y" && (params.value.horasEsc === 0 || params.value.horasEsc === null) ? "red" : "yellow";

        return (
            <div className={styles.container}>
                <div className={styles.containerValue}>
                    {params?.value.horasEsc === null ? 0 : params?.value.horasEsc}
                    <div className={`${styles.circle} ${styles[color]}`}></div>
                </div>
            </div>
        )
    }

    const cellHorasPP = (params) => {
        return (
            <div className={styles.container}>
                <div className={styles.containerValue}>
                    {params.value === null || params.value === "" ? 0 : params?.value}
                </div>
            </div>
        )
    }

    const cellCalendar = (params) => {
        return (
            <div className={styles.container}>
                <div className={styles.containerBtn}>
                    <CalendarMonthRounded />
                </div>
                <div className={styles.containerHover}>
                    {params?.value?.map((item, index) => (
                        <div key={index} className={styles.containerData}>
                            <p>{item.day}</p>
                            <p>{item.hrs ? item.hrs : 0}hs</p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const resetForm = () => {
        formik.setFieldValue("doc", "")
        formik.setFieldValue("escuelaId", "")
        formik.setFieldValue("estadoId", "")
        formik.setFieldValue("cargoId", "")
        formik.setFieldValue("estadoPP", "")
        updateList()
    };
    const getFilters = async () => {
        const esc = await escuelasGet(credentials)
        const car = await cargosGet(credentials)
        const distritos = await distritosGet(credentials)
        if (esc.length === 1) {
            setEscuelas(esc)
            formik.setFieldValue("escuelaId", esc[0].nqnEscuelaId)
        } else {
            setEscuelas([{ nqnEscuelaNombre: "Todas", nqnEscuelaId: "" }, ...esc])
        }
        if (car.length === 1) {
            setCargos(esc)
            formik.setFieldValue("cargoId", car[0].nqnCargoId)
        } else {
            setCargos([{ nqnCargoNombre: "Todos", nqnCargoId: "" }, ...car])
        }
    }
    useEffect(() => {
        getFilters()
        getDocentesData()
    }, [])
    return (
        <main className={s.main}>
            <Modal big={modalDetails.procede === ACTIONS.HISTORY} mid={modalDetails.procede === ACTIONS.INSERT} open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        // servicio={service}
                        // roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.HISTORY && (
                    <StatusModal
                        // servicio={service}
                        user={rowSelected.row}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "escuelaId", label: "Escuela", options: escuelas, firtsOptionKey: "nqnEscuelaId", firstOptionName: "nqnEscuelaNombre", firstOptionValue: "nqnEscuelaId" },
                                { type: "text", name: "doc", label: "DNI" },
                                { type: "select", name: "estadoId", label: "Estado", options: [{ name: "Todos", value: "" }, { name: "Alta", value: "A" }, { name: "Baja", value: "B" }], firtsOptionKey: "name", firstOptionName: "name", firstOptionValue: "value" },
                                { type: "select", name: "cargoId", label: "Cargo", options: cargos, firtsOptionKey: "nqnCargoId", firstOptionName: "nqnCargoNombre", firstOptionValue: "nqnCargoId" },
                                { type: "select", name: "estadoPP", label: "Estado PP", options: estadosPP, firtsOptionKey: "id", firstOptionName: "nombre", firstOptionValue: "id" },
                            ]
                        }
                        disabled={isLoading}
                    >
                    </Filters>
                    <Button onClick={() => resetForm()} disabled={isLoading} style={{ minWidth: "44px", width: "44px", height: "44px" }}><FilterAltOffRoundedIcon /></Button>                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { setForceUpload(!forceUpload); }}
                        disabled={rowSelected.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Asignar"}
                        handleClick={handleClick}
                        data={{
                            title: "Asignar Docente",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || permisos.docentes === "L"}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar calendarios"}
                        handleClick={handleClick}
                        data={{
                            title: `Editar calendarios`,
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id || permisos.docentes === "L"}
                    >
                        <EditCalendarRounded />
                    </HeaderButton>
                    <HeaderButton
                        text={"Historial calendarios"}
                        handleClick={handleClick}
                        data={{
                            title: `Historial calendarios`,
                            procede: ACTIONS.HISTORY
                        }}
                        disabled={!rowSelected.row.id || permisos.docentes === "N"}
                    >
                        <EventRepeatRounded />
                    </HeaderButton>
                    <HeaderButton
                        text={"Borrar docente"}
                        handleClick={handleClick}
                        data={{
                            title: `Borrar docente`,
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelected.row.id || permisos.docentes === "L" || rowSelected.row.informadoPP === "Y"}
                    >
                        <DeleteOutlineRounded />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGridServer
                    titles={titles}
                    updateList={getDocentesData}
                    forceUpload={forceUpload}
                    // forceReset={resetTable}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main >
    )
}
