import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { userCredentials, userServices } from 'recoilState/GlobalState';

import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, DeleteModal } from "./Actions";
import XGridServer from "components/UI/Grid/XGridServer";
import { Button, Buttons, HeaderButton } from "components";
import { ACTIONS } from "consts/Actions";
import { Add, Cached, EditOutlined, DeleteOutlineRounded } from '@mui/icons-material';
import { useService } from 'components/route/ServiceRoute';
import { nivelesGrid as titles } from "consts/titulos-de-tablas";
import { nivelModalidadGet } from 'services/services-mia/nivelModalidadGet';
import XGrid from 'components/UI/Grid/XGrid';
import Filters from 'components/Filters';
import { useFormik } from 'formik';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';

const activeValues = [
    { value: "", name: "Todos", id: "0" },
    { value: "Y", name: "Si", id: "1" },
    { value: "N", name: "No", id: "2" },
];

export default function Nivel() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [niveles, setNiveles] = useState([])
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const service = useService()
    const permisos = useRecoilValue(userServices);
    const credentials = useRecoilValue(userCredentials);

    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])

    const formik = useFormik({
        initialValues: {
            activo: "Y"
        },
        onSubmit: (values) => {
            getNivelesData()
        },
    });

    const getNivelesData = async (reset) => {
        setNiveles([])
        setIsLoading(true)
        const result = await nivelModalidadGet(credentials, { activo: reset ? "" : formik.values.activo })
        const newRows = result[0]?.nqnNivelModalidadId ? result.map((item) => {
            return {
                id: item.nqnNivelModalidadId,
                name: item.nqnNivelModalidadNombre,
                cod: item.nqnNivelModalidadCod,
                version: item.nqnNivelModalidadVersion,
                activo: item.nqnNivelModalidadActivo === "Y" ? "Activo" : "Inactivo",
            };
        }) : [];
        setNiveles(newRows)
        setIsLoading(false)
        return {
            rows: newRows,
            rowCount: newRows.length,
        };
    }
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const resetForm = () => {
        formik.setFieldValue("activo", "");
        getNivelesData(true)
    };
    useEffect(() => {
        if (modalDetails.isOpen === false) {
            getNivelesData()
        }
    }, [service, updateList, modalDetails.isOpen])
    // console.log(feriados)
    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                        setterRowSelected={setRowSelected}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        // roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "activo", label: "Activo", options: activeValues, firtsOptionKey: "id", firstOptionName: "name", firstOptionValue: "value" },
                            ]
                        }
                        disabled={isLoading}
                    />
                    <Button onClick={() => resetForm()} disabled={isLoading} style={{ minWidth: "44px", width: "44px", height: "44px" }}><FilterAltOffRoundedIcon /></Button>                 </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => getNivelesData()}
                        disabled={rowSelected.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar Nivel",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelected.row.id || permisos.nivel === "L"}
                    >
                        <DeleteOutlineRounded />
                    </HeaderButton>
                    <HeaderButton
                        text={"Crear"}
                        handleClick={handleClick}
                        data={{
                            title: "Crear Nivel",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || permisos.nivel === "L"}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Nivel",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id || permisos.nivel === "L"}
                    >
                        <EditOutlined />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGrid
                    rows={niveles}
                    titles={titles}
                    loading={isLoading}
                    updateList={getNivelesData}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    )
}
